import React from "react";
import { Loader } from "@stellar/design-system";
import { Avatar } from "components/Avatar";
import { Card } from "components/Card";
import { StatItem } from "components/StatItem";
import { formatAmount } from "helpers/convertAmount";
import { formatConversion } from "helpers/formatConversion";
import { useRedux } from "hooks/useRedux";
import { ActionStatus, LiquidityPoolAsset } from "types/types";
import "./styles.scss";

export const PoolStats = () => {
  const { poolDetails } = useRedux("poolDetails");

  const getAssetByAssetCode = ({
    assets,
    assetCode,
  }: {
    assets: LiquidityPoolAsset[];
    assetCode: string;
  }) => (assets || []).find((a) => a.assetCode === assetCode);

  const formatAssetAmount = (asset?: LiquidityPoolAsset) => {
    if (!asset) {
      return "";
    }

    if (asset["1d"]) {
      return `${formatConversion(asset["1d"])} ${asset.assetCode}`;
    }

    if (asset.amount) {
      return `${formatConversion(asset.amount)} ${asset.assetCode}`;
    }

    return `0 ${asset.assetCode}`;
  };

  if (!poolDetails.data) {
    return null;
  }

  const renderTokens = (assetSource: LiquidityPoolAsset[]) => {
    if (poolDetails?.data?.assetAvatars.length !== 2) {
      return null;
    }

    return (
      <>
        <div className="PoolStats__token">
          <Avatar source={[poolDetails.data.assetAvatars[0]]} />{" "}
          {formatAssetAmount(
            getAssetByAssetCode({
              assets: assetSource,
              assetCode: poolDetails.data.assetAvatars[0].altText,
            }),
          )}
        </div>
        <div className="PoolStats__token">
          <Avatar source={[poolDetails.data.assetAvatars[1]]} />{" "}
          {formatAssetAmount(
            getAssetByAssetCode({
              assets: assetSource,
              assetCode: poolDetails.data.assetAvatars[1].altText,
            }),
          )}
        </div>
      </>
    );
  };

  const statsData = [
    {
      id: "participants",
      label: "Participants",
      content: formatAmount(poolDetails.data.totalAccounts),
      details:
        "The number of participants in this specific pool (as measured by trustlines)",
    },
    {
      id: "vol-24h",
      label: "Volume 24h",
      content: renderTokens(poolDetails.data?.volume),
      details:
        "Total amount of each asset traded against the pool over the specified time period",
    },
    {
      id: "fees-24h",
      label: "Fees 24h",
      content: renderTokens(poolDetails.data?.earnedFees),
      details:
        "The value of historic fees generated by this pool over the specified time period",
    },
    {
      id: "pooled-tokens",
      label: "Pooled Tokens",
      content: renderTokens(poolDetails.data?.assets),
      details: "Total quantity of each asset in this pool",
    },
  ];

  return (
    <Card>
      {poolDetails.status === ActionStatus.PENDING ? (
        <Loader />
      ) : (
        <div className="PoolStats">
          <div className="PoolStats__items">
            {statsData.map((s) => (
              <React.Fragment key={s.id}>
                <StatItem label={s.label} details={s.details}>
                  {s.content}
                </StatItem>
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </Card>
  );
};
